import { LoginData } from '@/datamodel/logindata'
import { TableLimit } from '@/datamodel/types'
import { LoginParam } from '@/datamodel/urlparams/LoginParams'
import { LogoutParams } from '@/datamodel/urlparams/LogoutParams'
import { Options, Vue } from 'vue-class-component'
import { Action } from 'vuex-class'
import { generateRandomNumber } from '../../utils'

@Options({})
export class LoginMixin extends Vue {
    errorMessage = ''
    uname = ''
    pwd = ''
    buyIn = ''
    captcha = ''
    cpth = ''

    showMessage = true

    //#region Vuex
    @Action('showLoading') showLoading!: () => void
    @Action('hideLoading') hideLoading!: () => void
    @Action('authenticateUser') auth!: (model: LogoutParams) => Promise<string>
    @Action('setBetCoin') setBetCoin!: (betCoin: number) => void
    @Action('createSession') createSession!: (model: SessionModel) => void
    @Action('setTableLimits') setTableLimits!: (
        model: Array<TableLimit>
    ) => void
    @Action('setLobyNotice') setLobyNotice!: (notice: string) => void
    //#endregion

    //#region Vue Methods
    beforeMount(): void {
        this.resetCapcha()
    }
    //#endregion

    //#region Helpers

    //#endregion

    //#region Click Events
    doLoginWithCaptcha(): void {
        if (this.uname.length === 0) {
            this.errorMessage = '请输入用户名'
            this.resetCapcha()
            return
        }
        if (this.pwd.length === 0) {
            this.errorMessage = '请输入密码'
            this.resetCapcha()
            return
        }

        if (this.captcha + '' !== this.cpth + '') {
            this.errorMessage = '验证码不正确'
            this.resetCapcha()
            return
        }

        this.errorMessage = ''
        this.requestLogin()
    }

    doLogin(): void {
        if (this.uname.length === 0) {
            this.errorMessage = '请输入用户名'
            this.resetCapcha()
            return
        }
        if (this.pwd.length === 0) {
            this.errorMessage = '请输入密码'
            this.resetCapcha()
            return
        }

        this.errorMessage = ''
        this.requestLogin()
    }

    OnCLickedServer(): void {
        window.open(process.env.CUSTUMER_SERVICE, '_blank')
    }

    requestLogin(): void {
        // show loading
        this.showLoading()

        // call login api
        const model = new LoginParam(this.uname, this.pwd, parseInt(this.buyIn))

        this.auth(model)
            .then((response: string) => {
                const loginResponse = LoginData.Parse(response)
                if (!loginResponse.username) loginResponse.username = this.uname

                const {
                    sid,
                    username,
                    userPoint,
                    userLimitList,
                    notice
                } = loginResponse

                this.createSession({
                    uid: username,
                    sid: sid
                })

                this.setBetCoin(userPoint)
                this.setTableLimits(userLimitList)
                this.setLobyNotice(notice || '')

                this.errorMessage = ''
                this.$router.push('/')
            })
            .catch((err: string) => {
                this.hideLoading()
                this.errorMessage = err
                this.resetCapcha()
            })
    }

    resetCapcha(): void {
        this.captcha = generateRandomNumber(1000, 9999).toString()
    }
    //#region

    //#region Events
    checkCapchaLenght(e: KeyboardEvent): void {
        if (e.code === 'KeyE') {
            e.preventDefault()
            return
        }

        if (this.cpth.toString().length > 3 && e.code !== 'Backspace')
            e.preventDefault()
    }

    checkBuyinLength(e: KeyboardEvent): void {
        if (e.code === 'KeyE') {
            e.preventDefault()
            return
        }

        if (this.buyIn.toString().length > 8 && e.code !== 'Backspace')
            e.preventDefault()
    }
    //#endregion
}

import { getValueOfKeyValue } from '@/utils'
import { TableLimit } from './types'

export class LoginData {
    notice: string | undefined
    result: string | undefined
    username: string | undefined
    sid: string | undefined
    userPoint!: number
    userLimitList!: Array<TableLimit>
    static Parse(dataStr: string): LoginData {
        const result = new LoginData()

        const keyValues = dataStr.split('&')
        let uPoint

        keyValues.forEach((item: string) => {
            if (item.startsWith('notice=')) {
                result.notice = getValueOfKeyValue(item)
            } else if (item.startsWith('result=')) {
                result.result = getValueOfKeyValue(item)
            } else if (item.startsWith('UserName=')) {
                result.username = getValueOfKeyValue(item)
            } else if (item.startsWith('sid=')) {
                result.sid = getValueOfKeyValue(item)
            } else if (item.startsWith('UserPoint=')) {
                uPoint = getValueOfKeyValue(item)
                if (uPoint !== undefined) {
                    result.userPoint = parseInt(uPoint, 0)
                } else {
                    result.userPoint = 0
                }
            } else if (item.startsWith('xianhongArr=')) {
                const limits = getValueOfKeyValue(item)
                if (limits !== undefined) {
                    const xhValues = limits.split('$')
                    result.userLimitList = new Array<TableLimit>()
                    xhValues.forEach(xhValue => {
                        const values = xhValue.split('#')
                        const limitInfo: TableLimit = {}
                        // limitInfo.Id = parseInt(values[0])
                        limitInfo.Min = parseInt(values[1])
                        limitInfo.Max = parseInt(values[2])
                        result.userLimitList.push(limitInfo)
                    })
                }
            }
        })
        return result
    }
}

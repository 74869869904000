
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { LoginMixin } from './mixin'

@Options({})
export default class PcLogin extends Mixins(LoginMixin) {
    mounted(): void {
        //
    }
}

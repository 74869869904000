
import { EventBuss } from '@/utils/constants'
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { LoginMixin } from './mixin'

@Options({})
export default class MobileLogin extends Mixins(LoginMixin) {
    mounted(): void {
        // alert(document.body.getBoundingClientRect().width)
    }

    InstallApp(): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.emitter.emit(EventBuss.ON_ADD_TO_HOME_PAGE)
    }
}
